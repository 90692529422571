import React, { useEffect, useState } from 'react';

class TermsPage extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-12 grid-margin stretch-card d-flex justify-content-center mt-5 pt-5">
            <div className="card border-0">
              <div className="card-body">
                <div className="container pt-2">
                  <div className="text-center">
                    <h1 className="mb-3">Terms of Use</h1>
                    <p className="w-75 mx-auto mb-5">These Terms were last updated on 17 March 2020, with changes taking effect at midnight (in your timezone) on 17 April 2020 or immediately for Customers signing up with Mi Retail for a trial or Customers activating their account on or after 17 March 2020.</p>
                  </div>

                  <h2>High level summary</h2>
                  <p>A high level summary of the key changes is set out below:</p>

                  <ul>
                    <li>To better facilitate the needs of our customers for ongoing data access, we’ve increased our data retention period to 180 days after account cancellation.</li>
                    <li>We’ve made changes to our GDPR Data Processing Addendum to further clarify our role as a data processor with respect to requests received from data subjects seeking to exercise their rights under GDPR.</li>
                  </ul>

                  <h2>Terms</h2>
                  <p>These terms of use ("Terms") set out the terms and conditions for your use of the Mi Retail Services offered by Mi Retail. In using the Mi Retail Services, You acknowledge that you have read and understood these Terms, and are deemed to have agreed to these Terms on behalf of any person or entity for whom you are using the Mi Retail Services.</p>
                  <p>If you do not agree to any of these Terms, then you should not use the Mi Retail Services. All rights not expressly granted to Users in these Terms are reserved by Mi Retail.</p>
                  <p>We may change these Terms from time to time, and will endeavour to notify you of such changes via email or by displaying a message when you next use the Mi Retail Services. If you continue to use the Mi Retail Services, your use will be governed by the updated Terms.</p>

                  <h2>Definitions</h2>
                  <p>To help you read and understand these Terms, we've set out definitions of some key terms below:</p>
                  <p>"Customer" means the person or entity who registers to use the Mi Retail Service by creating a Mi Retail account. If an individual creates an account on behalf of their employer, then the employer will be deemed to be the Customer, and will be bound by these Terms.</p>
                  <p>"Authorised User" means any person or entity that a Customer authorises to use the Mi Retail Service on its behalf. This includes the Primary Admin (as nominated by the Customer) and other employees and personnel of the Customer who are authorised to use the Mi Retail Services on the Customer's behalf, but also includes support partners and other third parties with whom a Customer shares its Mi Retail account details (and for whose conduct the Customer is responsible).</p>
                  <p>"We" or "Mi Retail" eans Mi Retail Limited (New Zealand company number 2212542) and any of our related companies.</p>
                  <p>"Mi Retail Service"means Mi Retail's point of sale and inventory management products and services, and any features, technologies or functionality provided by those products or services, offered by us from time to time, including the Mi Retail Register and Mi Retail POS iOS applications ("Apps") and miretail.com.co ("Website"). Your use of the Mi Retail Service will also be subject to any allowances and/or limits that apply to the Mi Retail plan which you've purchased.</p>
                  <p>"You" means the Customer and (where the context permits) includes any Authorised Users.</p>

                  <h2>Account information</h2>
                  <p>You must be 18 years or older in order to access and use the Mi Retail Services. You agree to provide true, accurate, current and complete account information, and to maintain and promptly update your account information in order to ensure that it remains true, accurate, current and complete. We will use that information to contact you from time to time (including by sending you notices as required by these Terms relating to, for example, suspension of your account or changes to your plan), so it's important that you keep all of your contact details, and those of your Primary Admin and other Authorised Users, up to date.</p>
                  <p>You represent and warrant that you are not located in any U.S. embargoed countries or on any U.S. Government list of prohibited or restricted parties.</p>

                  <h2>Use of your account</h2>
                  <p>Mi Retail grants you a limited, non-exclusive, non-transferable, revocable licence to use the Mi Retail Services (subject to your account type) for the purposes of with the Customer's business and in accordance with these Terms.</p>
                  <p>You are responsible for any conduct or activity undertaken in your account (whether online or by calling our contact centre), including any changes made, and the input or modification of or access to any data or information in your account, by any of your Authorised Users. In particular, our primary point of contact with you will be your nominated Primary Admin, who will be considered as authorised by you to make changes, give instructions and receive notices on your behalf.</p>
                  <p>If:</p>
                  <ul>
                    <li>you are an Authorised User using or accessing the Mi Retail Services for the benefit of a Customer, then you are responsible for ensuring you have the right to do so from the relevant Customer (including all necessary authorisations to access, amend or remove data, or make changes to the Customer's account); or</li>
                    <li>you are a Customer, then you are responsible for authorising use or access of the Mi Retail Services by any Authorised User and will indemnify Mi Retail against any claims or loss relating to any Authorised User's use of or access to your account.</li>
                  </ul>
                  <p>We will have no responsibility to anyone other than the Customer, and the Mi Retail Services and these Terms are intended solely for the benefit of the Customer and not for any Authorised User(s).</p>

                  <h2>Cancellation of accounts</h2>
                  <p>We can cancel or suspend your account at any time on written notice to you. Such termination will be effective at the end of your then-current paid-up subscription period. We may also cancel or suspend your account immediately if, in our sole discretion:</p>
                  <ul>
                    <li>you have committed a material or persistent breach of these Terms or any other terms applying to your use of the Mi Retail Services;</li>
                    <li>we consider that provision of the Mi Retail Services, or use of them, in your territory or jurisdiction will or is likely to breach any applicable law;</li>
                    <li>we decide to withdraw the Mi Retail Services from your territory or jurisdiction altogether; or</li>
                    <li>you fail to comply with any limits or restrictions (eg, on the number of users, registers or transactions) applicable to your Mi Retail plan.</li>
                  </ul>
                  <p>You are entitled to cancel your account with Mi Retail at any time. You may cancel your account by following the prompts when you are logged into your account through the Apps or the Website. If you cancel your account before the end of your current paid up month or year, your cancellation will take effect immediately and you will not be entitled to any refund of Fees paid in advance (unless we agree otherwise).</p>
                  <p>If your account is cancelled, your account will be deactivated and all of your rights granted under these Terms will immediately come to an end (except to the extent that it is necessary for them to continue in respect of our ongoing storage of your data up to the date of permanent deletion). We are not liable for any loss or damage following, or as a result of, cancellation of your account, and it is your responsibility to ensure that any content or data which you require is exported, backed-up or replicated before cancellation.</p>

                  <h2>Fees and taxes</h2>
                  <p>The fees charged for use of the Mi Retail Services are set out on the Website (or as notified to you in the case of some exclusive plans) ("Fees") and are subject to change. We can change the Fees, and/or introduce new pricing packages and terms, at any time by giving you 30 days' prior notice (by email to your Primary Admin or by displaying a message the next time you use the Mi Retail Services). The new Fees or pricing/package terms will apply to you from expiry of that notice, unless you choose to cancel your account before the changes take effect. Also, the new Fees will apply to you immediately if you choose to change your plan/package (or if you reactivate your plan after cancellation). Fees are charged in advance on a monthly or annual basis and are non-refundable, including if you only use part of a month or year's subscription for the Mi Retail Services. If you do not want your subscription to renew at the end of your then-current paid-up subscription period, you must cancel your account before the end of your then-current paid-up subscription period. Unless required by law, we will not provide refunds in connection with the Mi Retail Services. A valid credit card number is required for paying all Fees.</p>
                  <p>If you are late in paying any Fees, we may charge a late fee of the greater of NZ$20 (excluding GST, if any) or up to 1.5% of the unpaid Fees.</p>
                  <p>If you are carrying out a free trial of the Mi Retail Services (as offered on the Website or the Apps), the free trial will begin on the day that your account is opened and end at the end of the published trial period. If you wish to continue to use the Mi Retail Services at the end of the free trial period, you will need to provide valid credit card details (unless you choose to use any free Mi Retail Services which we may offer from time to time). If you choose not to continue with the Mi Retail Services, then your account (and all data associated with it) will be deleted after the end of your free trial.</p>
                  <p>Unless otherwise stated, Fees are exclusive of all taxes, and you are responsible for any taxes that may arise. You indemnify and hold Mi Retail harmless against any claims by any tax authority for any underpayment of any sales, use, goods and services, value added or other tax or levy, and any penalties and/or interest. If you are required to withhold or deduct tax from the Fees, you will pay Mi Retail such additional amounts as are necessary to ensure receipt of the full amount which would have been received but for the deduction.</p>
                  <p>If you are located outside of New Zealand, then payment of your Fees could incur additional bank fees at the discretion of your bank. Bank fees may be charged for currency conversion and international settlement; and your charge may still be subject to additional fees even if you are being charged by Mi Retail in your local currency. If your credit card statement charge differs from your Mi Retail invoice, please contact your bank in the first instance. Mi Retail is not liable for any additional bank transaction fees that may be charged.</p>

                  <h2>You may upgrade or downgrade your account at any time</h2>
                  <p>You may upgrade or downgrade your account (for example, from Advanced to Multi-outlet, or from Advanced to Starter) at any time by following the prompts when you are logged into your account.</p>
                  <p>The upgrade or downgrade will become effective immediately. If you downgrade your account, no refunds will be paid in respect of any unused portion of any higher account offering. If you upgrade your account, the balance already paid for the rest of the term will be credited to your account. We will then immediately charge your credit card for any net amount due as a result of the upgrade and your account renewal date will be reset to the next available billing day.</p>
                  <p>Downgrading your account may cause the loss of content, features, or capacity of your account, and reduce the number of registers, users and/or transactions you can use or process. If you choose to downgrade your account, Mi Retail does not accept any liability for the resulting loss of data, content, features or capacity.</p>

                  <h2>Plan Limits and reasonable use</h2>
                  <p>The Mi Retail Service is available in a range of packages/plans, giving you options including in relation to the number of outlets, registers and users you can have, the number and total value of transactions you can process on the Mi Retail Service and the features and services you have access to, and the Fee you pay, as described on our Website from time to time (or as notified to you, in the case of some exclusive plans). We may update those plan limits at any time (eg, by increasing, reducing or removing limits) by updating the plan descriptions on our Website (or as notified to you, in the case of some exclusive plans). You'll be expected to comply with the updated plan limits from the next time you use the Mi Retail Service. You agree that we may use information generated by your use of the Services (eg, transaction volume and revenue) for the purposes of determining the most appropriate plan and pricing for you.</p>
                  <p>We may ask you to upgrade your Mi Retail account to a new plan if you exceed the limits that apply to your plan at any time.</p>
                  <p>We also reserve the right to suspend or restrict your account or your use of the Mi Retail Service, or disable any third party integrations you have, where we believe that your use isn't fair or reasonable or that it may cause degradation of the Mi Retail Services to other users. This includes circumstances where your use (or integration of your account with a third party) is creating a security or availability risk for Mi Retail or our other customers, is impacting (or may impact) the stability or performance of our systems, or is requiring disproportionate resource to deliver (eg, in terms of storage or processing requirements, support requests or helpdesk queries).</p>
                  <p>If you're using a free plan, then in addition to the other terms and conditions set out in these Terms, there are some other conditions that apply to you:</p>
                  <ul>
                    <li>Free plans are subject to published maximum usage period (which may change from time to time). After the published maximum usage period, you may be asked if you want to cancel your account or upgrade to a paid plan.</li>
                    <li>If you don't use or log in to your Mi Retail account for six (6) months, then we may treat your account as "inactive" and permanently delete your account (and all data associated with it) on 30 days' notice to you.</li>
                  </ul>

                  <h2>Mi Retail Services are provided "as is"</h2>
                  <p>The Mi Retail Services are provided on an "as is" and "as available" basis, and your use of them is at your sole risk. In particular, it is your sole responsibility to ensure that your use of the Mi Retail Services complies with any laws or regulations applicable to you and your business, and allows you to meet any legal obligations you might have. Such laws might include requirements regarding the retention or storage location of accounting, taxation and/or transaction records. We make no representation or warranty regarding your ability to comply with laws applicable to you when you use the Mi Retail Service.</p>
                  <p>We will try to promptly address (during normal business hours) all technical issues that arise in connection with the Mi Retail Services.</p>
                  <p>We do not warrant that: (a) the Mi Retail Services will meet your specific requirements; (b) the Mi Retail Services will be uninterrupted, timely, secure, or error-free; (c) the Mi Retail Services will be accurate or reliable; (d) the quality of any products, services, information, or other material purchased or obtained by you through the Mi Retail Services will meet your expectations; or (e) any errors in the Mi Retail Services will be corrected.</p>
                  <p>You acknowledge that Mi Retail may use third party suppliers to provide hardware, software, networking, connectivity, storage and other technology in order to provide the Mi Retail Services. The acts and omissions of those third party suppliers may be outside of Mi Retail's control, and Mi Retail does not accept any liability for any loss or damage suffered as a result of any act or omission of any third party supplier, save as otherwise expressly set out in the Agreement or in writing between the parties.</p>
                  <p>On behalf of itself and such third party suppliers, Mi Retail excludes any other warranties that may be implied or otherwise apply under statute or other applicable law, to the maximum extent permitted by law.</p>
                  <p>From time to time, we may add, make changes to or remove altogether features or functionality of the Mi Retail Services. If you're using an App, you may need to install a new or updated version before you can receive the benefit of those changes. We may also decide to cease providing all or some of the Mi Retail Services at any time, and nothing in these Terms is to be taken as a guarantee that the Apps or any Mi Retail Services will always be available, either in its current form or at all, or that we will support, maintain or continue to offer the Mi Retail Services, or the Apps or any version of them.</p>

                  <h2>Our liability is limited</h2>
                  <p>To the maximum extent permitted by law, Mi Retail shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, business, goodwill, use, data or other intangible losses (even if Mi Retail has been advised of the possibility of such damages), resulting from: (a) the use or the inability to use the Service (in whole or in part) or any technical malfunctions; (b) the cost of procurement of substitute goods and services; (c) unauthorised access to, or alteration of, your communications or data; (d) statements or conduct of any third party regarding the Mi Retail Services; or (e) any other matter relating to the Mi Retail Services.</p>
                  <p>In any case, Mi Retail's maximum aggregate liability under or in connection with these Terms or your use of the Mi Retail Services is limited to the amount of Fees paid by you in the past 12 months.</p>

                  <h2>You are responsible for your use of the Mi Retail Service and you indemnify us</h2>
                  <p>You are responsible for all activity that results from use of the Mi Retail Services through your account. You are responsible for maintaining the security of your account and password. Mi Retail will not be liable for any loss or damage that may result from any failure to keep User names and passwords secure.</p>
                  <p>You indemnify Mi Retail against all forms of liability, actions, proceedings, demands, costs, charges and expenses which Mi Retail may incur or suffer as a result of use of the Mi Retail Services through your account or as a result of your failure to comply with these Terms.</p>

                  <h2>No malicious or illegal use</h2>
                  <p>You must not: (a) use the Mi Retail Services in any unlawful manner (or if use of the Mi Retail Services would cause you to breach any law or regulation applicable to you), for any unlawful or fraudulent purpose, or in any manner inconsistent with these Terms; (b) infringe our intellectual property rights or those of any third party in relation to your use of the Mi Retail Services; (c) transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Mi Retail Services; (d) use the Mi Retail Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; (e) collect or harvest any information or data from the Mi Retail Services or our systems or attempt to decipher any transmissions to or from the servers running any Mi Retail Services; (f) disclose or distribute information relating to another User of the Mi Retail Services to any third party, or use any other User's information for any marketing purposes unless you have that User's express permission to do so; or (g) access or register User logins via bots or other automated methods.</p>

                  <h2>Reasonable use policy</h2>
                  <p>You agree to use the Mi Retail Services in a reasonable way (including, to avoid doubt, if you have subscribed for an 'unlimited' account). If we determine that your use of the Mi Retail Services is not reasonable or that your use is causing degraded performance of the Mi Retail Services for you or for other Users, we may impose limits on your use of the Mi Retail Services. Where possible, we will endeavour to give you at least 24 hours prior notice and request that your usage is reduced before imposing any limits.</p>
                  <p>Limits on your use of the Mi Retail Services may include (but are not limited to) the quantities and volumes of the following parameters, per retailer: (a) storage required to host and backup retailer data; (b) sales transactions per calendar month; (c) API (application programming interface) calls per five (5) minute period; (d) page views per five (5) minute period; (e) bandwidth usage per twenty four (24) hour period; (f) support tickets opened per calendar month; or (g) outlets, registers, users, products and customers.</p>

                  <h2>Intellectual property</h2>
                  <p>You agree that Mi Retail owns all of the intellectual property rights existing in the Mi Retail Services. You agree not to challenge or do anything inconsistent with such ownership. You may not publish or use Mi Retail's brand, branding or logos except with Mi Retail's prior written consent.</p>
                  <p>You grant Mi Retail a royalty-free, irrevocable, perpetual licence to use for our business purposes (including for product improvement, research and development, data analytics and other legitimate commercial purposes) information and/or data collected by Mi Retail through your use of the Mi Retail Services (provided that we aggregate or anonymise any information or data which you have submitted or inputted into the Services that we process on your behalf). Other than this right, Mi Retail claims no intellectual property rights in relation to the information or content you input into the Mi Retail Services.</p>
                  <p>You may provide us with comments, feedback or suggestions on Mi Retail Services, and you agree that we will be free to use, modify and incorporate such suggestions without any obligation to you.</p>

                  <h2>Data protection</h2>
                  <p>We will maintain appropriate technical and organizational measures to protect the security of the data or content that you input into the Mi Retail Services.</p>
                  <p>You are responsible for complying with all applicable data protection laws in respect of your use of the Mi Retail Services and with regard to any instructions you issue to Mi Retail with regard to the processing of personal data you provide to Mi Retail through the Services. In particular, you must ensure you have properly informed and obtained all necessary rights, authorizations or consents from any end-users, consumers, personnel or other individuals to whom the data relates, to enable Mi Retail to lawfully access their personal data under these Terms and to process their personal data outside of their country of residence.</p>
                  <p>You shall not provide (nor permit any User to provide) any Sensitive Data to Mi Retail. For the purpose of these Terms, "Sensitive Data" shall mean data revealing a person's racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetics, biometrics, health data, data about sex life or sexual orientation, or data about criminal convictions and offences.</p>
                  <p>If you are in the European Union and your use of the Mi Retail Services requires us to process personal data falling within the scope of the EU General Data Protection Regulation 2016/679, then you can download and execute our Data Processing Addendum ("DPA"). Once executed, the DPA shall apply to processing of such personal data and shall hereby be incorporated by reference.</p>

                  <h2>Third Party Integration Partners</h2>
                  <p>You acknowledge that in the provision of certain optional features and services, Mi Retail, on receipt of instructions from you, may be required to transfer certain data held in your Mi Retail account to, and otherwise interact with, third party partners who provide services that you have elected to integrate with the Mi Retail Services ("Third Party Integration Partners"). For a current list of our Third Party Integration Partners, see here.</p>
                  <p>You agree that if and to the extent such data transfer occurs, it is on your express election and instruction by virtue of you agreeing to use the Third Party Integration Partner's services. You are solely responsible for compliance with all applicable laws (including applicable data protection laws) in respect of the transfer of the data by Mi Retail to the Third Party Integration Partner, and for entering into separate contractual agreements with the Third Party Integration Partner. Mi Retail is in no way liable for the provision of the services or the handling and processing of any data by the Third Party Integration Partner. For the avoidance of doubt, such third parties are not Mi Retail's subprocessors for data protection purposes.</p>

                  <h2>Data deletion</h2>
                  <p>On expiry or termination of these Terms, or upon cancellation of your account, the data submitted by you to the Mi Retail Services will be deleted after 180 days, unless applicable laws or regulations require Mi Retail to retain the data for a longer period. To the extent that we continue to hold the data, it shall be securely isolated and protected from further processing except to the extent required by applicable law.</p>

                  <h2>Disputes</h2>
                  <p>If there is a dispute between the parties in relation to these Terms (or any previous version of them), either party must give the other party notice of the nature and details of the dispute, and neither party will commence any court or arbitration proceedings until the procedure described in this section has been complied with.</p>
                  <p>Within ten (10) business days of receipt of the notice of dispute, senior managers of the parties shall meet to endeavour to resolve the dispute. If the dispute is not resolved within twenty (20) business days of receipt of the notice of dispute, either party may by notice to the other party refer the dispute to mediation. If the dispute is not resolved within twenty (20) Business Days of receipt of the notice of dispute, either party may by notice to the other party refer the dispute to mediation. The mediation will be in Auckland, New Zealand and conducted under the LEADR New Zealand Incorporated (“LEADR”) standard mediation agreement. If the parties do not agree on a mediator or the mediator’s fees within ten (10) business days of receipt of the notice of mediation, the mediator shall be appointed or the fees set by the chair of LEADR (or their nominee) at the request of either party.</p>
                  <p>If the dispute cannot be resolved through mediation, then either party may refer the dispute to binding arbitration. The arbitration will be in Auckland, New Zealand and the results of the arbitration shall be non-appealable and binding on both parties.</p>

                  <h2>General</h2>
                  <p>We work with a number of partners, value added resellers and other third parties to promote, market and integrate other products and services with the Mi Retail Services. In some cases, we may receive a commission from those partners and third parties for referring our customers to them, or we may pay a commission to third parties where customers are referred to us.</p>
                  <p>If we don't insist that you perform any of your obligations under these Terms, or if we do not immediately enforce our rights against you, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations.</p>
                  <p>The Mi Retail Services are provided for use in business, so you are not a consumer. To the maximum extent permitted by law, any statutory or other consumer protection provisions (including in the Consumer Guarantees Act 1993 and in the Fair Trading Act 1986) do not apply to the Mi Retail Services, these Terms or our relationship with you.</p>
                  <p>Each clause in these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.</p>
                  <p>You are not permitted to assign, sub-licence, novate or transfer these Terms or any of the rights licensed under them. These Terms shall be governed by New Zealand law, and (subject to the 'Disputes' section above) you submit to the exclusive jurisdiction of the New Zealand courts for any matter or dispute arising in relation to these Terms.</p>

                  <h2>Additional Terms for Apple App Store downloads</h2>
                  <p>If you have downloaded an App from the Apple App Store, the following additional terms and conditions apply:</p>
                  <p>These Terms are solely between you and Mi Retail, and not with Apple. We (and not Apple) are solely responsible for the App and its content (subject to these Terms). You acknowledge that Apple has no obligation to furnish any maintenance or support services to you in connection with the App.</p>
                  <p>In the event of any failure of the App to conform to any warranty that might be contained or implied into these Terms, you may notify Apple, and Apple will refund the purchase price (if any) for the App. Except for the foregoing, to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App.</p>
                  <p>Any claim in connection with the App related to product liability, a failure to conform to applicable legal or regulatory requirements, claims under consumer protection or similar legislation or intellectual property infringement are governed by these Terms, and Apple is not responsible for such claims.</p>
                  <p>You must comply with the App Store Terms of use, including the Usage Rules.</p>
                  <p>Apple and its subsidiaries are third party beneficiaries to these Terms and, upon your acceptance of them, Apple will have the right to enforce these Terms against you.</p>
                  <p>All other terms and conditions of these Terms apply to your use of the App.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TermsPage;
