const React = require('react');
const PropTypes = require('prop-types');

class NavigationHeader extends React.Component {

  render () {
    let bindings = {
      style: {
        hasNewNotifications: { 'v-bind:class': '{ "alert-animation-active": vueBind.model.notifications && vueBind.model.notifications.length > 0 }' },
      }
    }
    return (
      <header className="header-area" id="header-area">
        <div className="dope-nav-container breakpoint-off n-dark">
          <div className="container">
            <div className="row">

              <nav className="dope-navbar justify-content-between" id="dopeNav">

                <a className="nav-brand logo-inverse" href="/">
                  <img src="/img/logo.svg" alt="logo" />
                </a>

                <a className="nav-brand d-none logo-normal" href="/">
                  <img src="/img/logo.svg" alt="logo" />
                </a>

                <div className="dope-navbar-toggler">
                  <span className="navbarToggler">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </div>

                <div className="dope-menu">

                  <div className="dopecloseIcon">
                    <div className="cross-wrap">
                      <span className="top"></span>
                      <span className="bottom"></span>
                    </div>
                  </div>


                  <div className="dopenav px-0">
                    <ul id="nav">
                      <li>
                        <a href="/#banner-section">{this.props?.locale?.NAV_HOME}</a>
                      </li>
                      <li>
                        <a href="/#about-section">{this.props?.locale?.NAV_WHY}</a>
                      </li>
                      <li>
                        <a href="/#feature-section">{this.props?.locale?.NAV_FEATURES}</a>
                      </li>
                      <li>
                        <a href="/pricing">{this.props?.locale?.NAV_PRICING}</a>
                      </li>
                      <li>
                        <a href="https://app.miretail.com.co/login">{this.props?.locale?.NAV_LOGIN}</a> 
                      </li>
                      <li className="p-4">
                        <a href="https://app.miretail.com.co/signup" className="btn btn-warning text-dark">{this.props?.locale?.NAV_CALL_ACTION}</a>
                      </li>
                    </ul>
                  </div>

                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default NavigationHeader;
