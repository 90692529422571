const React = require('react');
const PropTypes = require('prop-types');

class Footer extends React.Component {
  render () {
    const date = new Date()
    let bindings = {
      attr: {},
      events: {
        langListOnClick: { 'v-on:click': 'langListOnClick($event)' },
        langOnClick: { 'v-on:click': 'langOnClick($event)' }
      },
      model: {},
      lists: {},
      style: {},
      visibility: {}
    }
    return (
      <footer className="footer-section section-gap-half">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 footer-left">
              <a href="#">
                <img src="/img/logo-inverse.svg" alt="logo" />
              </a>
              <div
                {...bindings.events.langListOnClick}
                className="vhq-dropdown-selector js-vhq-dropdown-selector mt-2">
                <div className="vhq-dropdown-selector-current js-vhq-dropdown-selector-current vhq-dropdown-flag-row vhq-dropdown-flag-row-selected">
                  <span className="vhq-dropdown-selector-current-text">USA/Global</span>
                  <span className="vhq-dropdown-selector-current-arrow">
                    <svg height="10px" width="10px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.84 5.48">
                      <title>chevron10x6</title>
                      <polyline points="0.53 0.53 4.42 4.42 8.31 0.53" fill="none" stroke="#fff" strokeWidth="1.5"></polyline>
                    </svg>
                  </span>
                </div>
                <ul className="vhq-dropdown-selector-list js-vhq-dropdown-selector-list">
                  <li className="vhq-dropdown-selector-item" data-lang="us">
                    <span
                      {...bindings.events.langOnClick}
                      href="#" data-lang="us" className="vhq-dropdown-flag vhq-dropdown-flag-row">USA / Global</span>
                  </li>
                  <li className="vhq-dropdown-selector-item" data-lang="co">
                    <span
                      {...bindings.events.langOnClick}
                      href="#" data-lang="co" className="vhq-dropdown-flag vhq-dropdown-flag-co">Colombia / Latam</span>
                  </li>
                </ul>
              </div>
              <p className="copyright-text">Copyright &copy; {date.getFullYear()} <a href="https://vca.global/" target="_blank">
                Virtual capital of America</a>. {this.props?.locale?.FOOTER_COPYRIGHT_APENDIX}
              </p>
            </div>
            <div className="col-lg-7">
              <ul id="social">
                <li>
                  <a target="_blank" href="https://www.facebook.com/miretail.software/">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/miretail.software/">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
              <ul className="footer-menu">
                <li>
                  <a href="/affiliate-program">{this.props?.locale?.FOOTER_AFFILIATE}</a>
                </li>
                <li>
                  <a href="/terms">{this.props?.locale?.FOOTER_TERMS}</a>
                </li>
                <li>
                  <a href="/privacy">{this.props?.locale?.FOOTER_PRIVACY}</a>
                </li>
                <li>
                  <a href="/security">{this.props?.locale?.FOOTER_SECURITY}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
