import React, { useEffect, useState } from 'react';

class LandingIndexPage extends React.Component {
  render() {
    return (
      <>
        {/* Hero section */}
        <section className="space-bottom--lg hero hero--dark bg-gradient-2 hidden">
          <div className="background-holder background-holder--left-top background-holder--auto">
            <img src="/img/hero-13.png" alt="wave" className="background-image-holder" />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-lg-flex align-items-lg-start position-relative text-md-center">
                  <picture className="hero__image push-out-container mb-lg-40 mb-lg-0 pos-abs-lg-top-right">
                    <source type="image/webp" srcSet="/img/devices-1.webp" />
                    <img src="/img/devices-1.png" alt="Mi Retail preview product" />
                  </picture>

                  <div className="hero-content pb-100 py-lg-4 mb-100 text-left">
                    <h1 className="head-main">{this.props?.locale?.HOME_MAIN_TITLE_LINE_1} <br /><span>{this.props?.locale?.HOME_MAIN_TITLE_LINE_2}</span></h1>
                    <p className="head-call">{this.props?.locale?.HOME_MAIN_CALL_ACTION}</p>
                    <a href="https://app.miretail.com.co/signup" className="btn btn-warning mr-2">{this.props?.locale?.HOME_MAIN_CALL_ACTION_BTN}</a>
                    <a className="btn btn-outline-light" href="/contact">{this.props?.locale?.HOME_SECOND_CALL_ACTION_BTN}</a>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="hero__shape">
            <img src="/img/hero-svg-shape-4.svg" alt="hero bg" className="svg" />
          </div>

        </section>

        {/* Why us */}
        <section className="about-section section-gap-full relative" id="about-section">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6 col-md-6 about-left">
                <img className="img-fluid" src="/img/video.png" alt="" />
              </div>
              <div className="col-lg-5 col-md-5 about-right">
                <h3>{this.props?.locale?.HOME_WHY_US_SUBTITLE}</h3>
                <h2>{this.props?.locale?.HOME_WHY_US_TITLE}</h2>
                <p>{this.props?.locale?.HOME_WHY_US_P_1}</p>
                <p>{this.props?.locale?.HOME_WHY_US_P_2}</p>
                <a className="btn primary-btn" href="/why-us">{this.props?.locale?.HOME_WHY_US_BTN}</a>
              </div>
            </div>
          </div>
          <div className="floating-shapes">
            <span data-parallax='{"x": 150, "y": -20, "rotateZ":500}'>
              <img src="/img/shape/fl-shape-1.png" alt="" />
            </span>
            <span data-parallax='{"x": 250, "y": 150, "rotateZ":500}'>
              <img src="/img/shape/fl-shape-2.png" alt="" />
            </span>
            <span data-parallax='{"x": -180, "y": 80, "rotateY":2000}'>
              <img src="/img/shape/fl-shape-3.png" alt="" />
            </span>
            <span data-parallax='{"x": -20, "y": 180}'>
              <img src="/img/shape/fl-shape-4.png" alt="" />
            </span>
            <span data-parallax='{"x": 300, "y": 70}'>
              <img src="/img/shape/fl-shape-5.png" alt="" />
            </span>
            <span data-parallax='{"x": 250, "y": 180, "rotateZ":1500}'>
              <img src="/img/shape/fl-shape-6.png" alt="" />
            </span>
            <span data-parallax='{"x": 180, "y": 10, "rotateZ":2000}'>
              <img src="/img/shape/fl-shape-7.png" alt="" />
            </span>
            <span data-parallax='{"x": 60, "y": -100}'>
              <img src="/img/shape/fl-shape-9.png" alt="" />
            </span>
            <span data-parallax='{"x": -30, "y": 150, "rotateZ":1500}'>
              <img src="/img/shape/fl-shape-10.png" alt="" />
            </span>
          </div>
        </section>

        <section className="feature-section section-gap-full">
          <div className="container">
            <div className="row align-items-center feature-wrap">
              <div className="col-lg-4 header-left">
                <h2>{this.props?.locale?.HOME_HOW_START_TITLE}</h2>
                <a className="btn primary-btn" href="https://app.miretail.com.co/signup">{this.props?.locale?.HOME_HOW_START_BTN}
                  <span className="ti-arrow-right ml-2"></span>
                </a>
              </div>
              <div className="col-lg-8">
                <div className="row features-wrap">
                  <div className="col-md-6">
                    <div className="single-feature relative">
                      <div className="overlay overlay-bg"></div>
                      {/* <span className="ti-comments-smiley"></span> */}
                      <div className="d-flex mt-4">
                        <h3><span className="big-number mr-2">1</span></h3><h3>{this.props?.locale?.HOME_HOW_START_STEP_1_TITLE}</h3>
                      </div>

                      <p>{this.props?.locale?.HOME_HOW_START_STEP_1_DESC}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-feature relative">
                      <div className="overlay overlay-bg"></div>
                      {/* <span className="ti-light-bulb"></span> */}
                      <div className="d-flex mt-4">
                        <h3><span className="big-number mr-2">2</span></h3><h3>{this.props?.locale?.HOME_HOW_START_STEP_2_TITLE}</h3>
                      </div>
                      <p>{this.props?.locale?.HOME_HOW_START_STEP_2_DESC}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-feature relative">
                      <div className="overlay overlay-bg"></div>
                      {/*  <span className="ti-pencil-alt"></span> */}
                      <div className="d-flex mt-4">
                        <h3><span className="big-number mr-2">3</span></h3><h3>{this.props?.locale?.HOME_HOW_START_STEP_3_TITLE}</h3>
                      </div>
                      <p>{this.props?.locale?.HOME_HOW_START_STEP_3_DESC}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-feature relative">
                      <div className="overlay overlay-bg"></div>
                      {/* <span className="ti-import"></span> */}
                      <div className="d-flex mt-4">
                        <h3><span className="big-number mr-2">4</span></h3><h3>{this.props?.locale?.HOME_HOW_START_STEP_4_TITLE}</h3>
                      </div>
                      <p>{this.props?.locale?.HOME_HOW_START_STEP_4_DESC}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-section section-gap-full" id="feature-section">
          <div className="container">
            <div className="section-title">
              <h2 className="text-center">{this.props?.locale?.HOME_FEATURES_TITLE}</h2>
              <p className="text-center">{this.props?.locale?.HOME_FEATURES_SUBTITLE}</p>
            </div>
            <div className="row">
              <div className="col-lg-4  col-md-6 pb-30">
                <div className="single-service">
                  <p className="text-center"><i className="ti-credit-card text-muted"></i></p>
                  <h4 className="text-center">{this.props?.locale?.HOME_FEATURES_CARD_1_TITLE}</h4>
                  <p>{this.props?.locale?.HOME_FEATURES_CARD_1_DESC}</p>
                </div>
              </div>
              <div className="col-lg-4  col-md-6 pb-30">
                <div className="single-service">
                  <p className="text-center"><i className="ti-package text-muted"></i></p>
                  <h4 className="text-center">{this.props?.locale?.HOME_FEATURES_CARD_2_TITLE}</h4>
                  <p>{this.props?.locale?.HOME_FEATURES_CARD_2_DESC}</p>
                </div>
              </div>
              <div className="col-lg-4  col-md-6 pb-30">
                <div className="single-service">
                  <p className="text-center"><i className="ti-bar-chart text-muted"></i></p>
                  <h4 className="text-center">{this.props?.locale?.HOME_FEATURES_CARD_3_TITLE}</h4>
                  <p>{this.props?.locale?.HOME_FEATURES_CARD_3_DESC}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="single-service">
                  <p className="text-center"><i className="ti-id-badge text-muted"></i></p>
                  <h4 className="text-center">{this.props?.locale?.HOME_FEATURES_CARD_4_TITLE}</h4>
                  <p>{this.props?.locale?.HOME_FEATURES_CARD_4_DESC}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="single-service">
                  <p className="text-center"><i className="ti-money text-muted"></i></p>
                  <h4 className="text-center">{this.props?.locale?.HOME_FEATURES_CARD_5_TITLE}</h4>
                  <p>{this.props?.locale?.HOME_FEATURES_CARD_5_DESC}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="single-service">
                  <p className="text-center"><i className="ti-headphone-alt text-muted"></i></p>
                  <h4 className="text-center">{this.props?.locale?.HOME_FEATURES_CARD_6_TITLE}</h4>
                  <p>{this.props?.locale?.HOME_FEATURES_CARD_6_DESC}</p>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="skill-section section-gap-half">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 skill-left">
                <h2>{this.props?.locale?.HOME_BANNER_1_TITLE}</h2>
                <p>{this.props?.locale?.HOME_BANNER_1_DESC}</p>
              </div>
              <div className="col-lg-6 skill-right">
                <img className="img-fluid" src="/img/our-skill.jpg" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio-section section-gap-full">
          <div className="container">
            <div className="section-title">
              <h2 className="text-center">{this.props?.locale?.HOME_BANNER_2_TITLE}</h2>
              <p className="text-center">{this.props?.locale?.HOME_BANNER_2_DESC}</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <img className="img-fluid d-block mx-auto" src="/img/portfolio4.jpg" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="testimonial-section section-gap-full" id="testimonial-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 testimonial-left">
                <h2>{this.props?.locale?.HOME_TESTIMONIAL_TITLE}</h2>
                <p>{this.props?.locale?.HOME_TESTIMONIAL_DESC}</p>
                <a className="btn primary-btn" href="/contact">{this.props?.locale?.HOME_TESTIMONIAL_BTN} <span className="ml-2 ti-arrow-right"></span>
                </a>
              </div>
              <div className="col-lg-8 testimonial-right">
                <div className="testimonial-carusel  owl-carousel" id="testimonial-carusel">
                  <div className="single-testimonial item">
                    <p>{this.props?.locale?.HOME_TESTIMONIAL_1_DESC}</p>
                    <div className="user-details d-flex flex-row align-items-center">
                      <div className="img-wrap">
                        <img src="/img/avatar1.jpg" alt="" />
                      </div>
                      <div className="details">
                        <h4>{this.props?.locale?.HOME_TESTIMONIAL_1_USERNAME}</h4>
                        <p>{this.props?.locale?.HOME_TESTIMONIAL_1_COMPANY}</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-testimonial item">
                    <p>{this.props?.locale?.HOME_TESTIMONIAL_2_DESC}</p>
                    <div className="user-details d-flex flex-row align-items-center">
                      <div className="img-wrap">
                        <img src="/img/avatar2.jpg" alt="" />
                      </div>
                      <div className="details">
                        <h4>{this.props?.locale?.HOME_TESTIMONIAL_2_USERNAME}</h4>
                        <p>{this.props?.locale?.HOME_TESTIMONIAL_2_COMPANY}</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-testimonial item">
                    <p>{this.props?.locale?.HOME_TESTIMONIAL_3_DESC}</p>
                    <div className="user-details d-flex flex-row align-items-center">
                      <div className="img-wrap">
                        <img src="/img/avatar3.jpg" alt="" />
                      </div>
                      <div className="details">
                        <h4>{this.props?.locale?.HOME_TESTIMONIAL_3_USERNAME}</h4>
                        <p>{this.props?.locale?.HOME_TESTIMONIAL_3_COMPANY}</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-testimonial item">
                    <p>{this.props?.locale?.HOME_TESTIMONIAL_4_DESC}</p>
                    <div className="user-details d-flex flex-row align-items-center">
                      <div className="img-wrap">
                        <img src="/img/avatar4.jpg" alt="" />
                      </div>
                      <div className="details">
                        <h4>{this.props?.locale?.HOME_TESTIMONIAL_4_USERNAME}</h4>
                        <p>{this.props?.locale?.HOME_TESTIMONIAL_4_COMPANY}</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-testimonial item">
                    <p>{this.props?.locale?.HOME_TESTIMONIAL_5_DESC}</p>
                    <div className="user-details d-flex flex-row align-items-center">
                      <div className="img-wrap">
                        <img src="/img/avatar5.jpg" alt="" />
                      </div>
                      <div className="details">
                        <h4>{this.props?.locale?.HOME_TESTIMONIAL_5_USERNAME}</h4>
                        <p>{this.props?.locale?.HOME_TESTIMONIAL_5_COMPANY}</p>
                      </div>
                    </div>
                  </div>
                  <div className="single-testimonial item">
                    <p>{this.props?.locale?.HOME_TESTIMONIAL_6_DESC}</p>
                    <div className="user-details d-flex flex-row align-items-center">
                      <div className="img-wrap">
                        <img src="/img/avatar6.jpg" alt="" />
                      </div>
                      <div className="details">
                        <h4>{this.props?.locale?.HOME_TESTIMONIAL_6_USERNAME}</h4>
                        <p>{this.props?.locale?.HOME_TESTIMONIAL_6_COMPANY}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="clients-section">
          <div className="container">
            <div className="row clients-wrap">
              <div className="col-lg-3 col-md-6 col-sm-6 single-client">
                <img className="img-fluid" src="/img/logo/logo1.png" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 single-client">
                <img className="img-fluid" src="/img/logo/logo2.png" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 single-client">
                <img className="img-fluid" src="/img/logo/logo3.png" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 single-client">
                <img className="img-fluid" src="/img/logo/logo4.png" alt="" />
              </div>
            </div>
            <div className="row contact-wrap align-items-center justify-content-between">
              <div className="col-lg-3 col-md-6">
                <h5>Do you need an immediate solution?</h5>
              </div>
              <div className="col-lg-5 col-md-6">
                <a href="mailto:Support@dometheme.com.bd">
                  <h5><i className="ti-email"></i> Support@miretail.com.co</h5>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="tel:+573058126314">
                  <h5><i className="ti-mobile"></i>+57 305 8126314</h5>
                </a>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="contact-section contact-page-section padding-top-120" id="contact-section">
          <div className="container">
            <div className="home-contact">
              <h2 className="text-center">{this.props?.locale?.HOME_CONTACT_TITLE}</h2>
              <p className="text-center">{this.props?.locale?.HOME_CONTACT_DESC}</p>
            </div>
            <div className="row justify-content-center form-row">
              <div className="col-lg-9">
                <form id="contact-form" action="#">
                  <div className="row contact-form-wrap justify-content-center">
                    <div className="col-md-6 contact-name form-col">
                      <input name="name" id="name" className="form-control" type="text" placeholder={this.props?.locale?.HOME_CONTACT_NAME_LABEL} />
                    </div>
                    <div className="col-md-6 contact-email form-col">
                      <input name="mail" id="mail" className="form-control" type="email" placeholder={this.props?.locale?.HOME_CONTACT_EMAIL_LABEL} />
                    </div>
                    <div className="col-lg-12">
                      <textarea name="comment" id="comment" className="form-control" rows="8" placeholder={this.props?.locale?.HOME_CONTACT_MESSAGE_LABEL}></textarea>
                    </div>
                    <input type="submit" className="btn primary-btn" value={this.props?.locale?.HOME_CONTACT_SEND_BTN} id="submit-message" />
                    <div id="msg" className="message"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}

export default LandingIndexPage;
