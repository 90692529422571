import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";

import Navigation from '../components/navigation/_navMaintenance'
import Footer from '../components/footer/_footerMaintenance'

class Layout extends React.Component {
  render () {
    return (
      <html lang="en">

        <body>
          <section className="h-100">
            <Navigation {...this.props} />

            <div className="content-body">
              {/* Start Content */}
              <Outlet />
              {/* End Content */}
            </div>

            <Footer {...this.props} />
          </section>


          <script src="/jquery/jquery.slim.min.js"></script>
          <script src="/bootstrap/js/bootstrap.min.js"></script>

          <script src="/js/scripts.js"></script>

        </body>

      </html>
    );
  }
}

export default Layout;
