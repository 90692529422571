import React, { useEffect, useState } from 'react';

class MaintenanceGeneral extends React.Component {
  render() {
    return (
      <div className="error-404">Something was wrong</div>
    );
  }
}

export default MaintenanceGeneral;
