import React, { useEffect, useState } from 'react';

class ContactPage extends React.Component {
  render() {
    return (
      <>
        <div className="container pt-5">
          <h1 className="text-center my-4">Hello. How can we help you?</h1>

          <div className="row justify-content-center">
            <div className="col-lg-9 card mb-5">
              <form id="contact-form" action="#">
                <div className="row contact-form-wrap justify-content-center">
                  <div className="col-md-6 contact-name form-col">
                    <input name="name" id="name" className="form-control" type="text" placeholder="Name*"
                      onFocus="this.placeholder=''" onBlur="this.placeholder='Name*'" />
                  </div>
                  <div className="col-md-6 contact-email form-col">
                    <input name="mail" id="mail" className="form-control" type="email" placeholder="E-mail*"
                      onFocus="this.placeholder=''" onBlur="this.placeholder='Email*'" />
                  </div>
                  <div className="col-lg-12">
                    <textarea name="comment" id="comment" className="form-control" rows="8" placeholder="Message"
                      onFocus="this.placeholder=''" onBlur="this.placeholder='Message*'"></textarea>
                  </div>
                  <input type="submit" className="btn primary-btn" value="Send Message" id="submit-message" />
                  <div id="msg" className="message"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactPage;
