import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";

import TopNavigationGeneral from '../components/navigation/_navGeneral'
import TopNavigationDark from '../components/navigation/_navGeneralDark'
import Footer from '../components/footer/_footerGeneral'

class Layout extends React.Component {
  render() {

    return (
      <>
        <section className="app h-100 w-100">
          <TopNavigationGeneral locale={this.props.locale} />

          {/* Start Content */}
          <Outlet />
          {/* End Content */}

          <Footer locale={this.props.locale} />
        </section>

        <div className="scroll-top">
          <i className="ti-angle-up"></i>
        </div>

        <div id="loader" className="loader show fullscreen">
          <svg className="circular" width="48px" height="48px">
            <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" />
            <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10"
              stroke="#2f395d" />
          </svg>
        </div>
      </>
    );
  }
}

export default Layout;
