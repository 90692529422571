import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import {
  MaintenanceGeneral,
  ComingSoonPage,
  ContactPage,
  LandingIndexPage,
  PricingPage,
  PrivacyPage,
  SecuriyPage,
  TermsPage
} from './pages/index';
import {
  LayoutMaintenance,
  LayoutGeneral
} from './layouts/index';

import localeENG from './locales/en_US.locale.json';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutGeneral locale={localeENG.dictionary.shared} />}>
          <Route index element={<LandingIndexPage locale={{ ...localeENG.dictionary.home.index, ...localeENG.dictionary.shared }}/>} />
          
          <Route path="/pricing" element={<PricingPage locale={{ ...localeENG.dictionary.home.pricing, ...localeENG.dictionary.shared }}/>} />
          <Route path="/security" element={<SecuriyPage locale={{ ...localeENG.dictionary.home.security, ...localeENG.dictionary.shared }}/>} />
          <Route path="/terms" element={<TermsPage locale={{ ...localeENG.dictionary.home.terms, ...localeENG.dictionary.shared }}/>} />
          <Route path="/privacy" element={<PrivacyPage locale={{ ...localeENG.dictionary.home.privacy, ...localeENG.dictionary.shared }}/>} />
          <Route path="/contact" element={<ContactPage locale={{ ...localeENG.dictionary.home.contact, ...localeENG.dictionary.shared }}/>} />
          
        </Route>

        <Route path="*" element={<LayoutMaintenance />} >
          <Route path="*" element={<MaintenanceGeneral />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
