import React, { useEffect, useState } from 'react';

class SecurityPage extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-12 pt-5 mt-5">

            <section className="d-flex justify-content-center flex-column container text-center">
              <h2 className="mb-3">Keeping your business and customers safe and secure with Mi Retail</h2>
              <p className="w-75 mx-auto mb-5">Everything you need to know about how Mi Retail protects your business’ and customers’ data.</p>
            </section>

            <section className="d-flex justify-content-center py-4 bg-light">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center">
                      <h2 className="text-center col-12 col-xl-11 ">
                        Your data security is our top priority.
                      </h2>

                      <p className="col-12 col-lg-10 col-xl-9 text-center ">Data security is paramount in any retail business. Keeping your business’ and customers’ information secure enables you to build and maintain trust with shoppers and helps keep your business running smoothly.  That’s why Mi Retail makes it a top priority to protect your and your customers’ data. This page will offer an overview of the various steps we take to keep your business safe from threats.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="d-flex justify-content-center py-4 my-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center ">
                      <div className="row justify-content-center align-items-center ">
                        <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                          <div className="picture-column">
                            <img className="img-fluid " src="/img/security/Compliance.jpg" alt="Compliance" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-11 col-lg-6">
                          <p className="large">COMPLIANCE</p>
                          <div className="">
                            <h3>Mi Retail complies with all the necessary security standards and regulations.</h3>
                            <p>In addition to complying with the <strong>Payment Card Industry Data Security Standard (PCI DSS)</strong>, Mi Retail implements strict controls across our platform to abide by the EU's <strong>General Data Protection Regulation (GDPR)</strong> regarding the processing of personal data of individuals residing in the European Union (EU).</p>
                            <p>Mi Retail also engages in various forms of <strong>penetration testing</strong>. Aside from conducting annual internal and external penetrations of Mi Retail's office networks, <strong>we engage with independent parties to conduct application-level and infrastructure-level penetration tests at least once a year.</strong> The results of these tests are documented in our Vulnerability Management Policy and Procedures.</p>
                            <p>Mi Retail is hosted by Amazon Web Services (AWS). You can access all AWS compliance and audit reports through the AWS Artifact portal.<strong><br /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="d-flex justify-content-center py-4 bg-light">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center ">
                      <div className=" row justify-content-center align-items-center flex-row-reverse" >
                        <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                          <div className="picture-column">
                            <img className="img-fluid " src="/img/security/Infrastructure-and-endpoint-security.jpg" alt="Infrastructure and endpoint security" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-11 col-lg-6">
                          <p className="large">INFRASTRUCTURE AND ENDPOINT SECURITY</p>
                          <div className="">
                            <h3>We keep our network safe and secure against unauthorized access.</h3>
                            <p>We’re constantly enforcing measures to keep Mi Retail's network safe and secure. Such measures include system monitoring, logging, and alerting, as well as <strong>Distributed Denial-of-Service (DoS) Protection through AWS Sheild Advanced.</strong></p>
                            <p>And to protect Mi Retail from unauthorized access via remote devices, <strong>all company-issued devices to our employees are configured, updated, and tracked by Mi Retail's endpoint management solutions.</strong> By default, Mi Retail workstations are equipped with data encryption, firewalls, and strong passwords.</p>
                            <p>We also <strong>centrally manage access to Mi Retail's network and applications</strong>, and we continuously audit access and privileges so they're in line with Mi Retail's Access Control Policy. <strong><br /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="d-flex justify-content-center py-4 my-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center ">
                      <div className=" row justify-content-center align-items-center">
                        <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                          <div className="picture-column">
                            <img className="img-fluid " src="/img/security/Organizational-security.jpg" alt="Organizational security" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-11 col-lg-6">
                          <p className="large">ORGANIZATIONAL SECURITY</p>
                          <div className="">
                            <h3>We're continually educating Mi Retail employees on all things security.</h3>
                            <p>We want to make sure that everyone who works at Mi Retail understands how to protect themselves — and Mi Retail retailers — from threats. </p>
                            <p>To that end, we conduct <strong>employee background checks</strong> to verify each candidate's education and employment. Once hired, all Mi Retail employees are expected to adhere to Mi Retail's security policies, and<strong> the Security team closely monitors compliance. </strong></p>
                            <p>Mi Retail also <strong>conducts security awareness training for all Mi Retail employees</strong> once a year. The program covers everything from data classification and handling to password hygiene, physical security, and more. <strong><br /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="d-flex justify-content-center py-4 bg-light">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center ">
                      <div className=" row justify-content-center align-items-center flex-row-reverse">
                        <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                          <div className="picture-column">
                            <img className="img-fluid " src="/img/security/Application-security.jpg" alt="Application security" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-11 col-lg-6">
                          <p className="large">APPLICATION SECURITY</p>
                          <div className="">
                            <h3>We ensure that the Mi Retail app is secure and data is well-protected.</h3>
                            <p>How do we ensure a safe and secure experience when using the Mi Retail app? For starters, all <strong>data transmitted through Mi Retail are encrypted using the latest recommended secure cipher suites</strong>. We protect login confidentiality by hashing passwords using the bcrypt algorithm.</p>
                            <p>We protect the Mi Retail app from brute force attacks by <strong>implementing rate limiting and a check to see if you’re human</strong><strong>. Plus, all secret keys used in Mi Retail's product and app infrastructure are encrypted in AWS.</strong>  Any changes made to production apps and infrastructure are strictly controlled, and we closely review any changes before implementing them.</p>
                            <p>We're always monitoring and testing for dependencies and vulnerabilities.&nbsp;<strong>We invite security researchers to put Mi Retail’s security to the test through Mi Retail’s private bug bounty program hosted on HackerOne</strong>, so we’re always aware of any weaknesses that need to be corrected.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="d-flex justify-content-center py-4 my-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center ">
                      <div className=" row justify-content-center align-items-center ">
                        <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                          <div className="picture-column">
                            <img className="img-fluid " src="/img/security/Physical-security.jpg" alt="Physical security" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-11 col-lg-6">
                          <p className="large">PHYSICAL SECURITY</p>
                          <div className="">
                            <h3>We protect Mi Retail hardware, software, networks, and personnel from physical actions that can comprise security.</h3>
                            <p>A huge component of data security lies in protecting Mi Retail from physical threats. This is why <strong>all Mi Retail offices are controlled by badge access, alarm systems, and cameras</strong>. Every office also has shredders and secure bins for handling sensitive paper materials. </p>
                            <p>Mi Retail's production infrastructure is located in AWS data centers, where physical access is <strong>strictly controlled by security staff, video surveillance, intrusion detection systems and more.</strong><strong><br /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="d-flex justify-content-center py-4 bg-light">
              <div className="container">
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <div className="row justify-content-center ">
                      <div className=" row justify-content-center align-items-center flex-row-reverse">
                        <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                          <div className="picture-column">
                            <img className="img-fluid " src="/img/security/Operational-security.jpg" alt="Operational security" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-11 col-lg-6">
                          <p className="large">OPERATIONAL SECURITY</p>
                          <div className="">
                            <h3>We run a tight ship with our vendors and procedures.</h3>
                            <p>When a security incident occurs at Mi Retail, <strong>we immediately initiate our incident response (IR) procedures to identify, contain, and resolve the issue ASAP. </strong></p>
                            <p>Regarding vendor management, Mi Retail has an <strong>established and documented process for engaging a new vendor or supplier</strong> which involves an inventory of the asset, security risk assessment, and a legal review.<strong><br /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </>
    );
  }
}

export default SecurityPage;
