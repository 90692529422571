const React = require('react');
const PropTypes = require('prop-types');

class Footer extends React.Component {
  render () {
    return (

      <footer>
        {/* TODO: */}
      </footer>

    );
  }
}

export default Footer;
