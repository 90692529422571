import React, { useEffect, useState } from 'react';

class PricingPage extends React.Component {
  render() {
    return (
      <>
        <div className="container pt-5">
          <h1 className="text-center my-4">{this.props?.locale?.PRICING_MAIN_TITLE}</h1>
          <section className="pt-4 pb-5">
            <div className="d-lg-none btn-group w-100 d-flex justify-content-center align-items-center">
              <button type="button" className="btn-plan btn btn-outline-secondary" data-plan="lite"
                {...{ 'v-on:click': `planOnClick('lite')` }}>{this.props?.locale?.PRICING_LITE_PLAN_TITLE}</button>
              <button type="button" className="btn-plan btn btn-outline-secondary active" data-plan="pro"
                {...{ 'v-on:click': `planOnClick('pro')` }}>{this.props?.locale?.PRICING_PRO_PLAN_TITLE}</button>
              <button type="button" className="btn-plan btn btn-outline-secondary" data-plan="enterprise"
                {...{ 'v-on:click': `planOnClick('enterprise')` }}>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_TITLE}</button>
            </div>
            <div className="row justify-content-center gutter-xs-none vhq-pricing-cards align-items-stretch mt-3 mb-5">
              <div className="col-lg-4 col-12 card vhq-pricing-card vhq-pricing-card-side px-0" data-plan="lite">
                <div className="vhq-pricing-video-section">
                  <video autoPlay="autoplay" muted="muted" id="video-lite" className="d-none d-lg-block">
                    <source src="/img/pricing/lite-video.mp4" type="video/mp4" />
                  </video>
                  <img src="/img/pricing/plan-store_lite.svg" alt="Small store icon" className="d-block d-lg-none w-100" />
                  <div className="vhq-pricing-video-content">
                    <h3>{this.props?.locale?.PRICING_LITE_PLAN_TITLE}</h3>
                    <p>{this.props?.locale?.PRICING_LITE_PLAN_SUBTITLE}</p>
                  </div>
                </div>
                <div className="vhq-pricing-price-section">
                  <div className="vhq-pricing-price">
                    <span className="vhq-pricing-currency">$</span><strong>{this.props?.locale?.PRICING_LITE_PLAN_PRICE}</strong><span className="vhq-pricing-monthly">{this.props?.locale?.PRICING_LITE_PLAN_PERIODICITY}</span>
                    <p>{this.props?.locale?.PRICING_LITE_PLAN_PERIODICITY_LARGE}</p>
                  </div>
                  <hr />
                  <div className="vhq-pricing-plan">
                    <ul>
                      <li>{this.props?.locale?.PRICING_LITE_PLAN_MAIN_FEATURE_1}</li>
                      <li>{this.props?.locale?.PRICING_LITE_PLAN_MAIN_FEATURE_2}
                        <div className="vhq-pricing-hover-info">
                          <img src="/img/pricing/icon-25_info.svg" alt="Plus Icon" />
                          <p>{this.props?.locale?.PRICING_LITE_PLAN_MAIN_FEATURE_2_DESC}</p>
                        </div>
                      </li>
                      <li>{this.props?.locale?.PRICING_LITE_PLAN_MAIN_FEATURE_3}
                        <div className="vhq-pricing-hover-info">
                          <img src="/img/pricing/icon-25_info.svg" alt="Plus Icon" />
                          <p>{this.props?.locale?.PRICING_LITE_PLAN_MAIN_FEATURE_3_DESC}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="vhq-pricing-features-section">
                  <div className="vhq-pricing-features-header h4">{this.props?.locale?.PRICING_LITE_PLAN_DESC}</div>
                  <div className="d-block">
                    <div className="vhq-pricing-features">
                      <dl className="list list--single-line list--tick--green">
                        <dd>{this.props?.locale?.PRICING_LITE_PLAN_FEATURE_1}</dd>
                        <dd>{this.props?.locale?.PRICING_LITE_PLAN_FEATURE_2}</dd>
                        <dd>{this.props?.locale?.PRICING_LITE_PLAN_FEATURE_3}</dd>
                        <dd>{this.props?.locale?.PRICING_LITE_PLAN_FEATURE_4}</dd>
                        <dd>{this.props?.locale?.PRICING_LITE_PLAN_FEATURE_5}</dd>
                        <dd>{this.props?.locale?.PRICING_LITE_PLAN_FEATURE_6}</dd>
                      </dl>
                      <div className="vhq-pricing-features-button">
                        <a href="https://app.miretail.com.co/signup?plan=lite" className="btn btn-secondary btn-block">{this.props?.locale?.PRICING_LITE_PLAN_CALL_ACTION_BTN}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 card vhq-pricing-card vhq-pricing-card-center px-0 active" data-plan="pro">
                <div className="vhq-pricing-video-section">
                  <video autoPlay="autoplay" muted="muted" id="video-pro" className="d-none d-lg-block">
                    <source src="/img/pricing/pro-video.mp4" type="video/mp4" />
                  </video>
                  <img src="/img/pricing/plan-store_pro.svg" alt="Professional store icon" className="d-block d-lg-none w-100" />
                  <div className="vhq-pricing-video-content">
                    <h3>{this.props?.locale?.PRICING_PRO_PLAN_TITLE}</h3>
                    <p>{this.props?.locale?.PRICING_PRO_PLAN_SUBTITLE}</p>
                  </div>
                </div>
                <div className="vhq-pricing-price-section">
                  <div className="vhq-pricing-price">
                    <span className="vhq-pricing-currency"></span><strong>{this.props?.locale?.PRICING_PRO_PLAN_PRICE}</strong><span className="vhq-pricing-monthly">{this.props?.locale?.PRICING_PRO_PLAN_PERIODICITY}</span>
                    <p>{this.props?.locale?.PRICING_PRO_PLAN_PERIODICITY_LARGE}</p>
                  </div>
                  <hr className="vhq-pricing-per-outlet" />
                  <div className="vhq-pricing-plan">
                    <ul>
                      <li>{this.props?.locale?.PRICING_PRO_PLAN_MAIN_FEATURE_1}</li>
                      <li>{this.props?.locale?.PRICING_PRO_PLAN_MAIN_FEATURE_2}
                        <div className="vhq-pricing-hover-info">
                          <img src="/img/pricing/icon-25_info.svg" alt="Plus Icon" />
                          <p>{this.props?.locale?.PRICING_PRO_PLAN_MAIN_FEATURE_2_DESC}</p>
                        </div>
                      </li>
                      <li>{this.props?.locale?.PRICING_PRO_PLAN_MAIN_FEATURE_3}
                        <div className="vhq-pricing-hover-info">
                          <img src="/img/pricing/icon-25_info.svg" alt="Plus Icon" />
                          <p>{this.props?.locale?.PRICING_PRO_PLAN_MAIN_FEATURE_3_DESC}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="vhq-pricing-features-section">
                  <div className="vhq-pricing-features-header h4">{this.props?.locale?.PRICING_PRO_PLAN_DESC}</div>
                  <div className="d-block">
                    <div className="vhq-pricing-features">
                      <strong>{this.props?.locale?.PRICING_PRO_PLAN_FEATURES_RESUME}</strong>
                      <dl className="list list--single-line list--tick--green ">
                        <dd>{this.props?.locale?.PRICING_PRO_PLAN_FEATURE_1}</dd>
                        <dd>{this.props?.locale?.PRICING_PRO_PLAN_FEATURE_2}</dd>
                        <dd>{this.props?.locale?.PRICING_PRO_PLAN_FEATURE_3}</dd>
                        <dd>{this.props?.locale?.PRICING_PRO_PLAN_FEATURE_4}</dd>
                        <dd>{this.props?.locale?.PRICING_PRO_PLAN_FEATURE_5}</dd>
                        <dd>{this.props?.locale?.PRICING_PRO_PLAN_FEATURE_6}</dd>
                      </dl>
                      <div className="vhq-pricing-features-button">
                        <a href="https://app.miretail.com.co/signup?plan=pro" className="btn btn-secondary btn-block">{this.props?.locale?.PRICING_PRO_PLAN_CALL_ACTION_BTN}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 card vhq-pricing-card vhq-pricing-card-side px-0" data-plan="enterprise">
                <div className="vhq-pricing-video-section">
                  <video autoPlay="autoplay" muted="muted" id="video-enterprise" className="d-none d-lg-block">
                    <source src="/img/pricing/enterprise-video.mp4" type="video/mp4" />
                  </video>
                  <img src="/img/pricing/plan-store_enterprise.svg" alt="Enterprise store icon" className="d-block d-lg-none w-100" />
                  <div className="vhq-pricing-video-content">
                    <h3>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_TITLE}</h3>
                    <p>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_SUBTITLE}</p>
                  </div>
                </div>
                <div className="vhq-pricing-price-section">
                  <div className="vhq-pricing-price">
                    <h3 className="text-white">{this.props?.locale?.PRICING_ENTERPRISE_PLAN_PRICE}</h3>
                    <p>
                      <a href="/contact" className="btn btn-outline-light">{this.props?.locale?.PRICING_ENTERPRISE_PLAN_CALL}</a>
                    </p>
                  </div>
                  <hr />
                  <div className="vhq-pricing-plan">
                    <ul>
                      <li>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_MAIN_FEATURE_1}</li>
                      <li>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_MAIN_FEATURE_2}
                        <div className="vhq-pricing-hover-info">
                          <img src="/img/pricing/icon-25_info.svg" alt="Plus Icon" />
                          <p>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_MAIN_FEATURE_2_DESC}</p>
                        </div>
                      </li>
                      <li>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_MAIN_FEATURE_3}</li>
                    </ul>
                  </div>
                </div>
                <div className="vhq-pricing-features-section">
                  <div className="vhq-pricing-features-header h4">{this.props?.locale?.PRICING_ENTERPRISE_PLAN_DESC}</div>
                  <div className="d-block">
                    <div className="vhq-pricing-features">
                      <strong>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_FEATURES_RESUME}</strong>
                      <dl className="list list--single-line list--tick--green ">
                        <dd>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_FEATURE_1}</dd>
                        <dd>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_FEATURE_2}
                          <div className="vhq-pricing-hover-info">
                            <img src="/img/pricing/icon-25_info.svg" alt="Plus Icon" />
                            <p>{this.props?.locale?.PRICING_ENTERPRISE_PLAN_FEATURE_2_DESC}</p>
                          </div>
                        </dd>
                      </dl>
                      <div className="vhq-pricing-features-button">
                        <a href="/contact" className="btn btn-secondary btn-block">{this.props?.locale?.PRICING_ENTERPRISE_PLAN_CALL_ACTION_BTN}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center my-4">
              <div className="col-12 col-lg-4 col-xl-3">
                <a className="btn btn-primary btn-block text-uppercase" href="https://app.miretail.com.co/signup">{this.props?.locale?.PRICING_CALL_ACTION_PRICE_LIST}</a>
              </div>
            </div>
          </section>
          <section className="py-5">
            <h3 className="h3 text-center mb-5">{this.props?.locale?.PRICING_ESSENTIALS_TITLE}</h3>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                  <div className="row vhq-pricing-included-row">
                    <div className="col-6 col-lg-4 vhq-pricing-included mb-4">
                      <img src="/img/pricing/icon-25_cloud.svg" alt="Cloud Icon" />
                      {this.props?.locale?.PRICING_ESSENTIALS_CARD_1}
                    </div>
                    <div className="col-6 col-lg-4 vhq-pricing-included mb-4">
                      <img src="/img/pricing/icon-25_payments.svg" alt="Payments Icon" />
                      {this.props?.locale?.PRICING_ESSENTIALS_CARD_2}
                    </div>
                    <div className="col-6 col-lg-4 vhq-pricing-included mb-4">
                      <img src="/img/pricing/icon-25_support.svg" alt="Phone Support Icon" />
                      {this.props?.locale?.PRICING_ESSENTIALS_CARD_3}
                    </div>
                    <div className="col-6 col-lg-4 vhq-pricing-included mb-4">
                      <img src="/img/pricing/icon-25_autoupdate.svg" alt="Auto Update Icon" />
                      {this.props?.locale?.PRICING_ESSENTIALS_CARD_4}
                    </div>
                    <div className="col-6 col-lg-4 vhq-pricing-included mb-4">
                      <img src="/img/pricing/icon-25_products.svg" alt="Products Icon" />
                      {this.props?.locale?.PRICING_ESSENTIALS_CARD_5}
                    </div>
                    <div className="col-6 col-lg-4 vhq-pricing-included mb-4">
                      <img src="/img/pricing/icon-25_users.svg" alt="Users Icon" />
                      {this.props?.locale?.PRICING_ESSENTIALS_CARD_6}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <h3 className="h3 text-center mb-4">{this.props?.locale?.PRICING_ADDON_TITLE}</h3>
              <div className="row justify-content-center align-items-stretch">
                <div className="col-12 col-lg-4 vhq-pricing-addon">
                  <div className="vhq-pricing-addon-top vhq-pricing-addon-top-payments">
                    <h4 className="text-center"><img src="/img/pricing/icon-25_plus.svg" alt="Plus Icon" /> {this.props?.locale?.PRICING_ADDON_PAYMENTS_TITLE}</h4>
                    <p className="h3">{this.props?.locale?.PRICING_ADDON_PAYMENTS_SUBTITLE}</p>
                  </div>
                  <div className="vhq-pricing-addon-bottom">
                    <p>{this.props?.locale?.PRICING_ADDON_PAYMENTS_DESC}</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4 vhq-pricing-addon">
                  <div className="vhq-pricing-addon-top vhq-pricing-addon-top-hardware">
                    <h4 className="text-center"><img src="/img/pricing/icon-25_plus.svg" alt="Plus Icon" /> {this.props?.locale?.PRICING_ADDON_HARDWARE_TITLE}</h4>
                    <p className="h3">{this.props?.locale?.PRICING_ADDON_HARDWARE_SUBTITLE}</p>
                  </div>
                  <div className="vhq-pricing-addon-bottom">
                    <p>{this.props?.locale?.PRICING_ADDON_HARDWARE_DESC}</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4 vhq-pricing-addon">
                  <div className="vhq-pricing-addon-top vhq-pricing-addon-top-onboarding">
                    <h4 className="text-center"><img src="/img/pricing/icon-25_plus.svg" alt="Plus Icon" /> {this.props?.locale?.PRICING_ADDON_ONBOARD_TITLE}</h4>
                    <p className="h3">{this.props?.locale?.PRICING_ADDON_ONBOARD_SUBTITLE}</p>
                  </div>
                  <div className="vhq-pricing-addon-bottom">
                    <p>{this.props?.locale?.PRICING_ADDON_ONBOARD_DESC}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container text-center">
              <h3 className="h3">{this.props?.locale?.PRICING_CALL_ACTION_ADDON_TITLE}</h3>
              <p className="large mb-4">{this.props?.locale?.PRICING_CALL_ACTION_ADDON_SUBTITLE}</p>
              <div className="row justify-content-center">
                <div className="col-12 col-lg-4 col-xl-3">
                  <a className="btn btn-primary btn-block text-uppercase" href="https://app.miretail.com.co/signup">{this.props?.locale?.PRICING_CALL_ACTION_ADDON_BTN}</a>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <div className="row gutter-xs-none gutter-md-wide align-items-center justify-content-center">
                <div className="col-12 col-sm-11 col-lg-6 reveal-fade-in">
                  <div className="vhq-picture-column d-md-inline-block">
                    <img className="img-fluid" alt="A retailer and MI Retail support agent working together" src="/img/pricing/help.jpg" />
                  </div>
                </div>
                <div className="col-12 col-sm-11 col-lg-6">
                  <p className="h2">{this.props?.locale?.PRICING_CALL_ACTION_SUPPORT_TITLE}</p>
                  <p>{this.props?.locale?.PRICING_CALL_ACTION_SUPPORT_DESC}</p>
                  <div className="row vhq-pt-20">
                    <div className="col-12">
                      <a className="btn btn-primary text-uppercase" href="https://app.miretail.com.co/signup">{this.props?.locale?.PRICING_CALL_ACTION_SUPPORT_BTN}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default PricingPage;
